<template>
    <b-card no-body class="p-1" ref="userAccounts">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <div class="demo-spacing-0 m-1 d-flex justify-content-between align-items-center"
                 dir="rtl"
            >

            </div>

            <div class="relative-over-x">
                <b-row class="mt-1">
                    <b-col>
                        <b-row class="mb-1">
                            <b-col md="3" class="text-right">
                                حالت معامله
                                <feather-icon icon="AlertCircleIcon"
                                              size="20"
                                              v-tooltip="'در حالت اتوماتیک، عمل ترید و واریز رمز ارز به صورت خودکار صورت می گیرد و نیازی به تایید ادمین نیست.'"
                                />
                            </b-col>
                            <b-col md="9">
                                <b-form-checkbox
                                        switch
                                        inline
                                        class="ml-3 align-middle"
                                        style="min-height: 24px"
                                        v-model="autoTrade"
                                        @change="updateConfig('MAIN','AUTO_TRADE',autoTrade ? 'AUTO' : 'MANUAL')"
                                >{{ autoTrade ? 'حالت اتوماتیک فعال' : 'حالت دستی فعال' }}
                                </b-form-checkbox>
                            </b-col>
                        </b-row>

                        <b-row class="mb-1">
                            <b-col md="3" class="text-right">
                                درصد کمیسیون دعوت از دوستان:
                                <feather-icon icon="AlertCircleIcon"
                                              size="20"
                                              v-tooltip="'میزان درصدی از سود ترید که به کاربر دارای زیرمجموعه داده خواهد شد.'"
                                />
                            </b-col>
                            <b-col md="9">
                                <b-input-group>
                                    <b-form-input v-model="referralCommission"
                                                  dir="ltr"
                                                  class="text-left mb-1"
                                    />
                                    <b-input-group-append>
                                        <b-button variant="success"
                                                  @click="updateConfig('MAIN','REFERRAL_COMMISSION',referralCommission / 100)"
                                        >به روزرسانی
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>


                    </b-col>
                </b-row>
            </div>

        </b-overlay>
    </b-card>
</template>

<script>
import {
    BButton,
    BCard,
    BCol,
    BFormCheckbox,
    BOverlay,
    BRow,
    // BFormGroup,
    BFormInput,
    BInputGroup
} from "bootstrap-vue";

export default {
    name: "ExchangeInfo",
    components: {
        BCard,
        BOverlay,
        BButton,
        BCol,
        BRow,
        BFormCheckbox,
        // BFormGroup,
        BFormInput,
        BInputGroup,
    },
    data() {
        return {
            autoTrade: true,
            referralCommission: 30,
        }
    },
    methods: {
        updateConfig(type, key, value) {
            this.$axios.post('/configs', {
                'type': type.toUpperCase(),
                'key': key.toUpperCase(),
                'value': value
            })

            if (key === 'AUTO_TRADE') {
                this.state.exchangeIsAuto = (value === 'AUTO')
            }

            this.$swal({
                icon: 'success',
                title: 'اطلاعات با موفقیت ویرایش است.',
                confirmButtonText: 'تایید',
                customClass: {
                    confirmButton: 'btn btn-success',
                },
            })
        },
        async getData() {
            const res = await this.$axios.get('/configs')
            const data = res.data.data

            data.forEach(i => {
                if (i.key === 'AUTO_TRADE') {
                    this.autoTrade = i.value === 'AUTO'
                } else if (i.key === 'REFERRAL_COMMISSION') {
                    this.referralCommission = i.value * 100
                }

            })


        }
    },
    async mounted() {
        await this.getData();
    }
}
</script>

<style scoped>

</style>
